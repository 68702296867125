import partnero from './../../../partnero';

export default function embeddable_signup() {
    const programId = this.$parent.id;
    const embeddableSignupConfig = partnero.getConfig('embeddable_signup_config', programId);
    const programType = partnero.getConfig('type', programId);

    embeddableSignupConfig.host = this.$parent.$parent.$parent.host;

    if (parseInt(embeddableSignupConfig.enabled) !== 1 || programType !== 'affiliate') {
        return;
    }

    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', () => initialize(embeddableSignupConfig));
    } else {
        initialize(programId, embeddableSignupConfig);
    }
}

function initialize(programId, embeddableSignupConfig) {
    const targetDiv = document.getElementById(`po-partner-embeddable-signup-${programId}`);
    
    if (!targetDiv) {
        return;
    }

    const iframe = document.createElement('iframe');
    iframe.id = 'po-embeddable-signup-iframe';
    iframe.style.cssText = `
        width: 100%;
        height: 0;
        border: none;
        background: transparent;
        overflow: hidden;
    `;
    iframe.src = embeddableSignupConfig.source_url + '?&_t=' + Date.now();
    iframe.title = 'Partner Signup Form';
    
    targetDiv.appendChild(iframe);

    // Handle iframe resizing based on content
    window.addEventListener('message', (event) => {
        if (event.data?.type === 'po:partner-embeddable-signup:loaded') {
            iframe.style.height = `${event.data.height}px`;
        }
    });
}