import partnero from './../../../partnero';

export default function signup_popup() {
    const programId = this.$parent.id;
    const signupPopupConfig = partnero.getConfig('signup_popup_config', programId);
    const programType = partnero.getConfig('type', programId);

    signupPopupConfig.host = this.$parent.$parent.$parent.host;

    if (parseInt(signupPopupConfig.enabled) !== 1 || programType !== 'affiliate') {
        return;
    }

    // Check device visibility first
    if (!shouldShowOnCurrentDevice(signupPopupConfig)) {
        return;
    }

    // Then check page visibility
    if (!shouldShowOnCurrentPage(signupPopupConfig)) {
        return;
    }

    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', () => initializeTriggers(signupPopupConfig));
    } else {
        initializeTriggers(signupPopupConfig);
    }
}

function shouldShowOnCurrentDevice(signupPopupConfig) {
    if (!signupPopupConfig.visibility_device_settings) return true;

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isTablet = /iPad|Android(?!.*Mobile)|Tablet/i.test(navigator.userAgent);
    const isDesktop = !isMobile && !isTablet;

    switch (signupPopupConfig.visibility_device_settings) {
        case 'hide_desktops':
            return !isDesktop;
        case 'hide_tablets':
            return !isTablet;
        case 'hide_mobile_devices':
            return !isMobile;
        default:
            return true;
    }
}

function shouldShowOnCurrentPage(signupPopupConfig) {
    if (!signupPopupConfig.visibility_type || signupPopupConfig.visibility_type === 'all_pages') return true;

    const currentUrl = window.location.href;
    const pagesList = (signupPopupConfig.visibility_type_specific_pages || '').split('\n')
        .map(url => url.trim())
        .filter(url => url.length > 0);

    if (pagesList.length === 0) return true;

    const isUrlInList = pagesList.some(page => {
        const pageRegex = new RegExp(page.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/\*/g, '.*'));
        return pageRegex.test(currentUrl);
    });

    return signupPopupConfig.visibility_type === 'show_on_specific_pages' ? isUrlInList : !isUrlInList;
}

function initializeTriggers(signupPopupConfig) {
    let hasTriggered = false;
    let iframeLoaded = false;

    // Create and load iframe immediately
    const iframe = document.createElement('iframe');
    iframe.id = 'po-signup-popup-iframe';
    iframe.style.cssText = `
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        z-index: 99999999;
        display: none;
    `;
    iframe.src = signupPopupConfig.source_url + '?_t=' + Date.now();
    iframe.title = 'Partner signup';
    document.body.appendChild(iframe);

    const showPopup = () => {
        if (!hasTriggered && shouldShowBasedOnLastDisplay(signupPopupConfig)) {
            hasTriggered = true;
            if (iframeLoaded) {
                iframe.style.display = 'block';
            }
        }
    };

    // Time delay trigger
    if (signupPopupConfig.trigger_wait_enabled) {
        setTimeout(showPopup, signupPopupConfig.trigger_wait_seconds * 1000);
    }

    // Scroll percentage trigger
    if (signupPopupConfig.trigger_scroll_enabled) {
        window.addEventListener('scroll', () => {
            const scrollPercent = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
            if (scrollPercent >= signupPopupConfig.trigger_scroll_percentage) {
                showPopup();
            }
        });
    }

    if (signupPopupConfig.trigger_before_closing_enabled) {
        // Exit intent trigger
        document.addEventListener('mouseleave', (e) => {
            showPopup();
        });
    }

    // Handle message from iframe
    const handleMessage = (event) => {
        if (event.data?.type === 'po:partner-signup-popup:close') {
            iframe.remove();
            window.removeEventListener('message', handleMessage);
        }
        if (event.data?.type === 'po:partner-signup-popup:loaded') {
            iframeLoaded = true;
            if (hasTriggered) {
                iframe.style.display = 'block';
            }
        }
    };
    window.addEventListener('message', handleMessage);
}

function shouldShowBasedOnLastDisplay(signupPopupConfig) {
    // If frequency is 'always', always return true
    if (signupPopupConfig.frequency_unit === 'always') return true;

    const lastShown = localStorage.getItem('partnero-signup-popup-last-shown');
    if (!lastShown) return true;

    const lastShownDate = new Date(parseInt(lastShown));
    const now = new Date();
    const diffTime = Math.abs(now - lastShownDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // Convert config duration to days
    let requiredDays = 0;
    if (signupPopupConfig.frequency_unit === 'month') {
        requiredDays = signupPopupConfig.frequency_value * 30;
    } else if (signupPopupConfig.frequency_unit === 'year') {
        requiredDays = signupPopupConfig.frequency_value * 365;
    } else {
        requiredDays = parseInt(signupPopupConfig.frequency_value || 0);
    }

    return diffDays >= requiredDays;
}
