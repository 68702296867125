import ctx from "./ctx";
import visitor from "./visitor";
import settings from "./settings";
import load from "./load";
import router from "../../../router.js";
import type from "./type";
import portal_widget from "./portal_widget";
import utm_tags from "./utm_tags";
import signup_popup from "./signup_popup";
import embeddable_signup from "./embeddable_signup";

export default function _id(id) {
    this.id ??= id;
    return router({ctx, settings, visitor, load, type, portal_widget, utm_tags, signup_popup, embeddable_signup});
}
